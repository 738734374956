<template>
  <div class="admin-data">
    <div class="container">
      <div class="admin-data__header">
        <div class="admin-data__data">
          <div class="admin-data__data-title">
            Всего городов:
          </div>
          <div v-if="cities" class="admin-data__data-value">
            {{ cities.paginate.total }}
          </div>
        </div>
        <ButtonElement text="Добавить город" fill="empty" link="/cities/new" />
      </div>
      <div v-if="cities" class="admin-data__table">
        <el-table :data="cities.items" style="width: 100%">
          <el-table-column prop="name" label="Название" />
          <el-table-column label="Дата регистрации">
            <template #default="scope">
              <div :key="scope.row.id">{{ fixedDate(scope.row.created_at) }}</div> 
            </template>
          </el-table-column>
          <el-table-column width="50">
            <template #default="scope">
              <el-dropdown placement="bottom-end" trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                  <img src="../assets/icons/icon-more.svg" alt="">
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item :command="{ name: 'edit', id: scope.row.id }">
                      Редактировать
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <Pagination 
        v-if="cities && cities.paginate.total > 1" 
        :pagesInfo="cities.paginate" 
        @changePage="getCities($route.query)"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '../components/elements/Pagination'
import ButtonElement from '../components/elements/ButtonElement'
import { DateParser } from '@/mixin/date_parser'
 
export default {
  name: 'Cities',
  mixins: [DateParser],
  components: {
    Pagination,
    ButtonElement,
  },
  data() {
    return {
      cities: null,
    }
  },
  mounted() {
    this.getCities(this.$route.query)
  },
  methods: {
    handleCommand(command) {
      if (command.name === 'edit') {
        this.$router.push(`/cities/${command.id}`)
      }
    },
    getCities(params) {
      this.$store.dispatch('Cities/getAdminCities', params)
        .then(resp => {
          this.cities = resp.data.data
        })
        .catch(err => {
          console.log(err.response.data.errors)
        })
    }
  }
}
</script>
